// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./Carousel.scss";

export default function Carousel() {
  return (
    <section className="carousel">
      <div className="carousel__bigBox">
        <div className="carousel__header">
          <h2 className="carousel__heading">OUR WORK SPEAKS</h2>
          <p className="carousel__copy">
          Visually stunning and user-friendly websites aimed at helping our clients achieve their online goals.
          </p>
        </div>
        <Swiper
          // install Swiper modules mobile swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={17}
          slidesPerView={1}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={(swiper) => console.log(swiper.activeIndex)}
          centeredSlides={true}
          slidesOffsetBefore={42}
          width={327}
          initialSlide={1}
          className="carousel__swiper carousel__swiper--mobile"
        >
          <SwiperSlide className="card">
            <div className="card__copy">
              <p className="card__text card__text--top">Web Design</p>
              <p className="card__text card__text--middle">
                24 & ME WEBSITE DESIGN
              </p>
              <p className="card__text card__text--bottom">NEW PROJECT</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card">
            <div className="card__copy">
              <p className="card__text card__text--top">Web Design</p>
              <p className="card__text card__text--middle">
                24 & ME WEBSITE DESIGN
              </p>
              <p className="card__text card__text--bottom">NEW PROJECT</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card">
            <div className="card__copy">
              <p className="card__text card__text--top">Web Design</p>
              <p className="card__text card__text--middle">
                24 & ME WEBSITE DESIGN
              </p>
              <p className="card__text card__text--bottom">NEW PROJECT</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card">
            <div className="card__copy">
              <p className="card__text card__text--top">Web Design</p>
              <p className="card__text card__text--middle">
                24 & ME WEBSITE DESIGN
              </p>
              <p className="card__text card__text--bottom">NEW PROJECT</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card">
            <div className="card__copy">
              <p className="card__text card__text--top">Web Design</p>
              <p className="card__text card__text--middle">
                24 & ME WEBSITE DESIGN
              </p>
              <p className="card__text card__text--bottom">NEW PROJECT</p>
            </div>
          </SwiperSlide>
        </Swiper>

        <Swiper
          // install Swiper modules desktop swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={20}
          slidesPerView={2}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={(swiper) => console.log(swiper.activeIndex)}
          centeredSlides={true}
          slidesOffsetBefore={""}
          width={800}
          initialSlide={2}
          loop={true}
          className="carousel__swiper carousel__swiper--desktop"
        >
          <SwiperSlide className="card">
            <div className="card__copy">
              <p className="card__text card__text--top">Web Design</p>
              <p className="card__text card__text--middle">
                24 & ME WEBSITE DESIGN
              </p>
              <p className="card__text card__text--bottom">NEW PROJECT</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card">
            <div className="card__copy">
              <p className="card__text card__text--top">Web Design</p>
              <p className="card__text card__text--middle">
                24 & ME WEBSITE DESIGN
              </p>
              <p className="card__text card__text--bottom">NEW PROJECT</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card">
            <div className="card__copy">
              <p className="card__text card__text--top">Web Design</p>
              <p className="card__text card__text--middle">
                24 & ME WEBSITE DESIGN
              </p>
              <p className="card__text card__text--bottom">NEW PROJECT</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card">
            <div className="card__copy">
              <p className="card__text card__text--top">Web Design</p>
              <p className="card__text card__text--middle">
                24 & ME WEBSITE DESIGN
              </p>
              <p className="card__text card__text--bottom">NEW PROJECT</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card">
            <div className="card__copy">
              <p className="card__text card__text--top">Web Design</p>
              <p className="card__text card__text--middle">
                24 & ME WEBSITE DESIGN
              </p>
              <p className="card__text card__text--bottom">NEW PROJECT</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}
