import "./Services.scss";
import gabeLaptop from "../../assets/gabe_laptop.png";
import betPhone from "../../assets/bet_phone.png";

function Services() {
  return (
    <section className="services">
      <section className="services__left">
        <img src={gabeLaptop} alt="" className="services__laptop" />
        <img src={betPhone} alt="" className="services__phone" />
      </section>
      <section class="services__right">
        <div className="services__header">
          <h3 className="services__subheading">How We Help</h3>
          <h2 className="services__heading">OUR SERVICES</h2>
        </div>
        <ul className="services__copy">
          <li className="services__bullet">
            Responsive Custom Website Development
          </li>
          <li className="services__bullet">Custom E-Commerce Website</li>
          <li className="services__bullet">Research & Design</li>
          <li className="services__bullet">Logo & Brand Development</li>
          <li className="services__bullet">Existing Website Review</li>
        </ul>
        <button className="services__button">GRAB IT NOW</button>
      </section>
    </section>
  );
}

export default Services;
