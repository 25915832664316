import React from "react";
import "./hero.scss";
import coqui from "../../assets/Mobile/Coqui__mobile.png";
import karinaScreen from "../../assets/karina_screen.png";
import gabeScreen from "../../assets/gabe_screen.png";
import betScreen from  "../../assets/bet_screen.png";

export default function Hero() {
  return (
    <div className="hero">
      <div className="hero__bigBox">
        <section className="hero__background">
          <div className="hero__motto">CREATE EFFFECTIVE WEBSITES</div>
          <img src={coqui} alt="coqui frog" className="hero__coqui" />
        </section>
        <div className="hero__text">
        Looking to enhance your online presence? <br/> Create a website that attracts, engages, and converts your target audience.
        </div>
        <button className="hero__button">OUR SERVICES</button>
      </div>
      <div className="hero__images">
          <img src={karinaScreen} alt="coqui frog" className="hero__karina" />
          <img src={gabeScreen} alt="coqui frog" className="hero__gabe" />
          <img src={betScreen} alt="coqui frog" className="hero__bet" />
        </div>
    </div>
  );
}
