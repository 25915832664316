import React from "react";
import "./HomePage.scss";
import Hero from "../../components/hero/hero";
import Services from "../../components/Services/Services"
import Principle from "../../components/Principle/Principle";
import Versus from "../../components/Versus/Versus";
import Carousel from "../../components/Carousel/Carousel";
import ContactForm from "../../components/ContactForm/ContactForm";

const HomePage = () => {
  return (
    <div>
      <Hero />
      <Services />
      <Principle />
      <Versus />
      <Carousel />
      <ContactForm />
    </div>
  );
};

export default HomePage;