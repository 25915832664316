import Hamburger from "./Hamburger";
import { useState } from "react";
import "./Header.scss";
import logo from "../../assets/Coqui Digital.svg";
import headerCoqui from "../../assets/coqui_header.png";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

export default function Nav() {
  const handleClickHome = () => {
    if (window.document.location.pathname === "/") {
      window.scrollTo(0, 0);
    }
  };

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  return (
    <div>
      <div className="navigation">
        <div className="navigation__bigbox">
          <Link to="/" onClick={handleClickHome} className="header-logo">
            <img src={headerCoqui} className="coqui-header-logo" alt="logo" />
            <img src={logo} alt="BET Group Brokers Logo" />
          </Link>

          <ul>
            <Link
              to="/"
              className="nav-link nav-link--home"
              onClick={handleClickHome}
            >
              Home
            </Link>
            <Link to="/" className="nav-link" onClick={handleClickHome}>
              Portfolio
            </Link>
            <Link to="/services" className="nav-link">
              FAQ
            </Link>
            <a href="#contact" className="nav-link">
              Contact
            </a>
            <HashLink smooth to="/#about" className="nav-link">
              About Us
            </HashLink>
          </ul>
          <div className="header-logo-box">
            <Link onClick={handleClickHome} className="header-logo-1">
              <img src={logo} alt="BET Group Brokers Logo" />
            </Link>

            <div className="hamburger" onClick={toggleHamburger}>
              <Hamburger isOpen={hamburgerOpen} />
            </div>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        .navigation ul {
          display: flex;
          // justify-content: space-between;
          flex-wrap: nowrap;

          width: 100vw;
          padding: 0px;
          overflow: hidden;
          z-index: 10;
          left: 0px;
        }
        .navigation ul Link {
          list-style-type: none;
          padding-right: 10px;
        }
        .hamburger {
          display: none;
          z-index: 6;
        }
        @media (max-width: 1124px) {
          .hamburger {
            display: initial !important;
            padding-top: 10px;
            margin-left: 10px;
            z-index: 6;
          }

          .navigation ul {
            display: ${hamburgerOpen ? "flex" : "none"};
            background-color: #ffffff;
            height: 100vh;
            flex-direction: column;
            align-items: center;
            width: 100vw;
            margin-top: 80px;
            margin-right: 200px;
            position: fixed;
          }
        }
      `}</style>
    </div>
  );
}
