import "./Principle.scss";
import gabeTablet from "../../assets/gabe_tablet.png";
import stockPhone from "../../assets/stock_phone.png";

function Principle() {
  return (
    <section className="principle">
      <section className="principle__left">
        <div className="principle__header">
          <h3 className="principle__subheading">How We Care</h3>
          <h2 className="principle__heading">OUR PRINCIPLE</h2>
        </div>
        <ul className="principle__copy">
          <li className="principle__bullet">Provide clear and transparent communication with clients to ensure their satisfaction.</li>
          <li className="principle__bullet">Continuously evaluate and improve processes and workflows to maximize efficiency and quality.</li>
          <li className="principle__bullet">Offer ongoing support and maintenance services to ensure clients' websites stay relevant and effective.</li>
          <li className="principle__bullet">Foster a culture of collaboration and teamwork within the agency and with our clients.</li>

        </ul>
      </section>
      <section className="principle__right">
        <img src={gabeTablet} alt="" className="principle__tablet" />
        <img src={stockPhone} alt="" className="principle__phone" />
      </section>
    </section>
  );
}

export default Principle;
