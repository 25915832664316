import React from "react";
import "./Contact.scss";
import phone from "../../assets/Mobile/phone.svg";
import email from "../../assets/Mobile/email.svg";

export default function Contact() {
  return (
    <div className="contact">
      <div className="contact__bigBox">
        <h2>HOW CAN WE HELP?</h2>
        <p>
        We're here to assist in enhancing your online presence. Reach out to our team of experts to discuss your project and receive a personalized quote.
        </p>
        <div className="contact__infoBox">
          <img src={phone} alt="phone" className="contact__phone" />
          <h4>CUSTOMER CARE NUMBER</h4>
        </div>
        <p className="contact__number">1-800-COQUI-DIGITAL</p>
        <div className="contact__infoBox">
          <img src={email} alt="email" className="contact__email" />
          <h4>SUPPORT EMAIL</h4>
        </div>
        <p className="contact__emailAddy">info@coquidigital.co</p>
      </div>
    </div>
  );
}
