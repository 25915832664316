import "./App.scss";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage.jsx";
import FaqPage from "./pages/FAQPage/FaqPage";
import PortfolioPage from "./pages/PortfolioPage/PortfolioPage";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/faq" element={<FaqPage />} />
        <Route exact path="/portfolio" element={<PortfolioPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
