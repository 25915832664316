import React from "react";
import "./Footer.scss";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import indeed from "../../assets/indeed.svg";
import footerLogo from "../../assets/footer_logo.png";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer__bigBox">
        <div className="footer__info">
          <h2 className="footer__mobile">Get In Touch!</h2>
          <p className="footer__mobile">
            the quick fox jumps over the lazy dog
          </p>
          <img src={footerLogo} className="footer__logo" alt="logo" />
          <div className="footer__logos">
            <img src={facebook} alt="facebook logo" className="footer__icons" />
            <img src={twitter} alt="twitter logo" className="footer__icons" />
            <img src={indeed} alt="indeed logo" className="footer__icons" />
          </div>
        </div>
        <div className="footer_info">
          <h3>About Us</h3>
          <p>Company Information</p>
          <p>Our Team</p>
          <p>Our Vision</p>
        </div>
        <div className="footer__info">
          <h3 className="footer__legal">Legal</h3>
          <p>Privacy Policy</p>
          <p>Terms & Conditions</p>
          <p>Cookie Policy</p>
          <p>Refunds & Returns</p>
          <p>Disclaimer</p>
        </div>
        <div className="footer__info">
          <h3 className="footer__contactInfo">Contact Information</h3>
          <p>P.O box</p>
          <p>info@coquidigital.co</p>
          <p className="footer__last">1-800-COQUI-DIGITAL</p>
        </div>
      </div>
    </div>
  );
}
