import "./Versus.scss";
import versusImg from "../../assets/versus_img.png";

function Versus() {
  return (
    <section className="versus">
      <section className="versus__left">
        <img src={versusImg} alt="" className="versus__img" />
      </section>
      <section className="versus__right">
        <div className="versus__header">
          <h3 className="versus__subheading">Why Choose Us?</h3>
          <h2 className="versus__heading">
            CUSTOM <span>VS</span> WEBSITE BUILDER
          </h2>
        </div>
        <ul className="versus__copy">
          <li className="versus__bullet">
          Professional custom design tailored to your specific needs.
          </li>
          <li className="versus__bullet">A team of experienced designers and developers working on your project.</li>
          <li className="versus__bullet">Ongoing support and maintenance services to ensure your website stays up-to-date and relevant.</li>
          <li className="versus__bullet">
          Expert guidance and advice throughout the design and development process.
          </li>
          <li className="versus__bullet">
          Higher quality design and user experience (UX) that can help you stand out from the competition.
          </li>
        </ul>
        <button className="versus__button">GRAB IT NOW</button>
      </section>
    </section>
  );
}

export default Versus;
