import React from "react";
import "./ContactForm.scss";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import Contact from "../Contact/Contact";

function ContactForm() {
  const [name, setName] = useState();
  const [subject, setSubject] = useState();
  const [email, setEmail] = useState();
  const [desc, setDesc] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    let templateParams = {
      name,
      subject,
      email,
      desc,
    };
    console.log(templateParams);
    emailjs
      .send(
        "Coqui_Digital",
        "Coqui_Digital",
        templateParams,
        "Nuqe0NeqdhxaEBRR8"
      )
      .then((res) => console.log(res.status));
    e.target.reset();
  };

  return (
    <div className="contactBox">
      <Contact />
      <div className="form">
        <form className="form__box" onSubmit={handleSubmit}>
          <div className="form__label">
            <label>Full Name</label>
            <input
              type="text"
              placeholder="John Smith"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form__label">
            <label>Email Address</label>
            <input
              type="email"
              placeholder="johnsmith@email.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form__label">
            <label>Subject</label>
            <input
              placeholder="Inquiry"
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="form__label">
            <label>What are you contacting about?</label>
            <textarea
              placeholder="Description"
              onChange={(e) => setDesc(e.target.value)}
            ></textarea>
          </div>
          <button className="form__button">Send</button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
